import styled from "styled-components"
import { Link } from "gatsby"
import colors from "../color"
import { size, family } from "../font"

const BlueText = styled.span`
  color: ${colors.darkBlue};
`

const GreenText = styled.span`
  background: rgb(119, 173, 123);
  background: linear-gradient(
    0deg,
    rgba(119, 173, 123, 1) 35%,
    rgba(208, 213, 162, 1) 68%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const Title = styled.h3`
  font-size: ${size.L};
  font-family: ${family.Archivo};
  font-weight: 400;
  letter-spacing: 0.1em;
  margin-left: 4vw;
  @media only screen and (max-width: 800px) {
    font-size: 28px;
    margin-left: 0;
  }
`

const Text = styled.p`
  font-size: ${size.XS};
  line-height: 1.4rem;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 4vw;
  @media only screen and (max-width: 800px) {
    font-size: 12px;
    line-height: 18px;
    margin-left: 0;
  }
`

const Button = styled.button`
  ${props =>
    props.little
      ? `font-size: ${size.XXS}; padding: 0px 20px;`
      : `font-size: ${size.XS}; padding: 2px 50px;`}
  color: ${colors.darkBlue};
  border: solid 2px ${colors.darkBlue};
  border-radius: 5px;
  font-family: ${family.Alata};
  letter-spacing: 0.1em;
  position: relative;
  background: ${props => (props.background ? props.background : "white")};
  margin-top: 40px;
  z-index: 50;
  transition: 0.3s ease;
  &:hover {
    color: white;
    background: ${colors.darkBlue};
  }
  @media only screen and (max-width: 800px) {
    margin-top: 0;
    background: ${props =>
      props.noBackground ? "transparent" : props.background};
  }
`
const QuoteHome = styled.span`
  font-size: ${size.S};
  line-height: 35px;
  font-family: ${family.Archivo};
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 800px) {
    width: 90%;
    font-size: 12px;
    line-height: 20px;
  }
`
const NumberStringHome = styled.span`
  font-family: Alata, sans-serif;
  font-size: ${size.XXXXS};
  line-height: 17px;
  letter-spacing: 0.1em;
  @media only screen and (max-width: 900px) {
    display: none;
  }
`
const NumberMobile = styled.span`
  display: none;
  @media only screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    margin: 0 0 10px 0;
    span {
      font-family: Alata, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: ${size.XXS};
      line-height: 14px;
      letter-spacing: 0.1em;
    }
  }
`
const TitleHome = styled.div`
  font-family: ${family.Archivo};
  font-size: calc(${size.XXXXS} + 2vw);
  letter-spacing: 0.1em;
  font-weight: 300;
  margin: 5vh 0;
  line-height: calc((${size.XXXXS} + 2vw) * 1.2);
  mark {
    color: ${colors.darkBlue};
    background: none;
  }
  @media only screen and (max-width: 900px) {
    margin: 20px 0 10px 0;
    font-size: 32px;
    line-height: 38px;
    ${props => props.paddingTop && "padding-top: 50px;"}
  }
`
const SectionTitle = styled.p`
  position: absolute;
  font-size: 10px;
  font-family: ${family.Alata};
  line-height: 14px;
  left: 6vw;
  top: 6vw;
  text-align: left;
`

const MainTitle = styled.h1`
  font-size: 1.8rem;
  font-family: ${family.Archivo};
  text-align: center;
  letter-spacing: 0.05em;
  margin-bottom: 10vh;
  font-weight: 100;
`

const StaticTitle = styled.h2`
  font-size: ${size.SSM};
  font-family: ${family.Archivo};
  text-align: left;
  letter-spacing: 0.05em;
  font-weight: 100;
  margin-bottom: 2vh;
`

const Subtitle = styled.p`
  font-weight: bold;
  font-size: ${size.XS};
  text-align: left;
  margin-bottom: 0;
  margin-top: 2vh;
`

const StaticText = styled(Text)`
  margin: 0;
  font-size: ${size.XS};
  line-height: 1.2rem;
  margin-bottom: 0;
`

const Alata = styled.span`
  font-family: ${family.Alata};
  font-size: 110%;
`

const LinkBack = styled(Link)`
  font-size: ${size.XS};
  color: ${colors.darkBlue};
  border: solid 2px ${colors.darkBlue};
  border-radius: 5px;
  font-family: ${family.Alata};
  letter-spacing: 0.1em;
  position: relative;
  background: white;
  z-index: 50;
  transition: 0.3s ease;
  text-decoration: none;
  padding: 10px 30px;
  width: fit-content;
  margin-top: 30px;
  &:hover {
    color: white;
    background: ${colors.darkBlue};
  }
  @media only screen and (max-width: 800px) {
    margin-top: 0;
  }
`

export {
  BlueText,
  GreenText,
  Title,
  Text,
  Button,
  SectionTitle,
  NumberStringHome,
  NumberMobile,
  TitleHome,
  MainTitle,
  StaticTitle,
  Subtitle,
  StaticText,
  Alata,
  LinkBack,
  QuoteHome,
}
