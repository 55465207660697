import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { family, size } from "../../elements/font"
import colors from "../../elements/color"
import { RichText } from "prismic-reactjs"

const MouseOut = styled.div`
  position: fixed;
  z-index: 2050;
  transition: transform 0.1s, opacity 0.3s;
  will-change: transform;
  pointer-events: none;
  opacity: 0;
  &.appear {
    opacity: 1;
  }
  &.disappear {
    opacity: 0;
  }
  &.hover::after {
    transform: scale(0.5);
    opacity: 0;
  }
  &.hover-article::after,
  &.hover-bubble::after,
  &.big::after {
    opacity: 0;
  }
  &.arrow::after {
    content: "";
    background: #00000080;
    z-index: -1;
    width: 100px;
    height: 100px;
    color: #00000080;
    transition: transform 0.3s, width 0.3s ease, height 0.3s ease;
    transform: translate(-30%, -30%);
    border: none;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border: solid 2px #00000050;
    border-radius: 100%;
    transition: transform 0.3s, opacity 0.3s;
  }
  @media only screen and (max-width: 800px) {
    display: none;
  }
`

const MouseIn = styled.div`
  position: fixed;
  z-index: 2050;
  transition: transform 0.05s ease;
  will-change: transform;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  opacity: 0;
  &.appear {
    opacity: 1;
  }
  &.disappear {
    opacity: 0;
  }
  &.hover::after {
    transform: scale(2.5);
    opacity: 0.6;
  }
  &.hover-article,
  &.hover-bubble,
  &.big {
    z-index: 10;
  }
  &.hover-article .text {
    opacity: 0.5;
  }
  &.hover-article .image {
    opacity: 0.7;
  }
  &.hover-article::after,
  &.hover-bubble::after {
    content: "";
    background: var(--tooltip-color);
    z-index: -1;
    width: 200px;
    height: 200px;
    color: #00000080;
    transition: transform 0s, width 0.3s ease, height 0.3s ease, color 0.3s 0.2s;
    transform: translate(-50%, -50%);
  }
  &.hover-bubble::after {
    content: attr(data-circle);
    width: 150px;
    height: 150px;
    display: flex;
    font-family: ${family.Alfa};
    font-size: ${size.XXXS};
    font-weight: 100;
    line-height: 15px;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 20px;
    color: #00000080;
  }
  &.big.bubble1 > .content-container.bubble1,
  &.big.bubble2 > .content-container.bubble2,
  &.big.bubble3 > .content-container.bubble3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1;
  }
  &.big.bubble2::after {
    background: ${colors.circle4};
  }
  &.big.bubble3::after {
    background: ${colors.circle5};
  }
  &.big::after {
    background: ${colors.circle3};
    width: 400px;
    height: 400px;
    color: black;
    transition: transform 0s, width 0.3s ease, height 0.3s ease, color 0.3s 0.2s;
    transform: translate(-50%, -50%);
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 8px;
    background: black;
    color: transparent;
    border-radius: 100%;
    transition: transform 0.3s, opacity 0.3s;
  }
  .text {
    font-family: ${family.Alfa};
    font-size: 13px;
    opacity: 0;
    transition: opacity 0.3s ease;
    transform: translate(-50%, -200%);
  }
  &.arrow::after {
    opacity: 0;
  }
  .arrow-text {
    opacity: 0;
    font-family: ${family.Alata};
    font-size: 80px;
    color: #ffffff90;
    transition: opacity 0.3s ease;
    transform: translate(-10%, -570%);
  }
  &.arrow .arrow-text {
    opacity: 1;
  }
  & .content-container .title,
  & .content-container .bigText {
    font-family: ${family.Alfa};
    text-align: center;
    width: 80%;
  }
  & .content-container .bigText {
    font-size: ${size.XXS};
    line-height: 20px;
    width: 55%;
  }
  .bubble1 {
    padding-top: 30px;
  }
  .bubble2 {
    .bigText {
      width: 65%;
    }
  }
  .bubble3 {
    padding-top: 50px;
  }
  & > .content-container {
    display: none;
    flex-direction: column;
    width: 500px;
    height: 500px;
    transform: translate(-50%, -50%);
    z-index: 50;
    align-items: center;
    opacity: 0;
    > * {
      transform: translateY(-50px);
    }
  }
  @media only screen and (max-width: 800px) {
    display: none;
  }
`

const ImageArticle = styled.div`
  width: 80px;
  height: 80px;
  opacity: 0;
  transform: translate(-50%, -80%);
  transition: opacity 0.3s ease;
`

const Mouse = () => {
  const data = useStaticQuery(graphql`
    query {
      medium: file(relativePath: { eq: "logo_medium.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      prismicIndex {
        data {
          body {
            ... on PrismicIndexBodyNovliniScore {
              items {
                title_buble {
                  text
                }
                text_buble {
                  raw
                }
              }
            }
          }
        }
      }
    }
  `)
  const prismic = data.prismicIndex.data

  return (
    <>
      <MouseOut className="mouse" />
      <MouseIn className="mouse-middle">
        <ImageArticle className="image">
          <Img loading="eager" fluid={data.medium.childImageSharp.fluid} />
        </ImageArticle>
        <p className="text">lire notre article</p>
        <p className="arrow-text">&rsaquo;</p>
        <div className="content-container bubble1">
          <p className="title">{prismic.body[4].items[0].title_buble.text}</p>
          <div className="bigText">
            {RichText.render(prismic.body[4].items[0].text_buble.raw)}
          </div>
        </div>
        <div className="content-container bubble2">
          <p className="title">{prismic.body[4].items[1].title_buble.text}</p>
          <div className="bigText">
            {RichText.render(prismic.body[4].items[1].text_buble.raw)}
          </div>
        </div>
        <div className="content-container bubble3">
          <p className="title">{prismic.body[4].items[2].title_buble.text}</p>
          <div className="bigText">
            {RichText.render(prismic.body[4].items[2].text_buble.raw)}
          </div>
        </div>
      </MouseIn>
    </>
  )
}

export default Mouse
