const colors = {
    darkBlue: '#1F4ED2',
    lightBlue: '#1aa1fb',
    circle1: '#0DFF93',
    circle2: '#BD19FF',
    circle3: '#05FF00',
    circle4: '#FF3919',
    circle5: '#BAFF0D'
}

export default colors