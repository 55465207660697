/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useRef, useContext } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import PageTransition from "gatsby-plugin-page-transitions"
import styled from "styled-components"
import Cookie from "../../components/cookies"
import Cookies from "js-cookie"

import { Header } from "./header"
import Footer from "./footer"
import Mouse from "./mouse"
import "./layout.css"
import AppContext from "../Context"

const LayoutStyled = styled.div`
  padding: 0 0 0 0;
  overflow: hidden;
`
const Layout = ({
  children,
  pageValueMenu,
  headerString,
  hasScrolled,
  connexionClick,
  footerText,
  green = false,
  slide = false,
  blackLogo = true,
  noMenu = false,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }

      prismicContact {
        data {
          lien {
            text
          }
        }
      }
    }
  `)

  const isValid = Cookies.get("cookiesAcceptedNovlini")

  const { isMobile, hasCursor } = useContext(AppContext)

  const $mouse = useRef(null)
  const $mouseMiddle = useRef(null)

  const handleMouseMove = _e => {
    if (!hasCursor) return

    const x = _e.clientX
    const y = _e.clientY

    if (!$mouse.current) $mouse.current = document.querySelector(".mouse")
    if (!$mouseMiddle.current)
      $mouseMiddle.current = document.querySelector(".mouse-middle")

    if (!$mouse.current.classList.contains("appear")) {
      $mouse.current.classList.add("appear")
      $mouseMiddle.current.classList.add("appear")
    }
    $mouse.current.style.transform = `translate(${x - 20}px, ${y - 20}px)`
    $mouseMiddle.current.style.transform = `translate(${x - 4}px, ${y - 4}px)`
  }

  useEffect(() => {
    if (!hasCursor) return
    // Add event on hover on all element in site with class 'click'
    const $hovers = document.querySelectorAll(".click")
    const $articles = document.querySelectorAll(".article")
    const $bubbles = document.querySelectorAll(".bubble")
    const $bigBubbles = document.querySelectorAll(".bigBubble")
    const $nomouses = document.querySelectorAll(".nomouse")
    const $horizontalScrolls = document.querySelectorAll(".horizontal-scroll")
    const $mouse = document.querySelector(".mouse")
    const $mouseMiddle = document.querySelector(".mouse-middle")

    for (const $hover of $hovers) {
      $hover.addEventListener("mouseenter", () => {
        $mouse.classList.add("hover")
        $mouseMiddle.classList.add("hover")
      })
      $hover.addEventListener("mouseleave", () => {
        $mouse.classList.remove("hover")
        $mouseMiddle.classList.remove("hover")
      })
    }
    for (const $article of $articles) {
      $article.addEventListener("mouseenter", () => {
        const color = $article.getAttribute("data-color")
        const style = $mouseMiddle.getAttribute("style")
        $mouseMiddle.setAttribute(
          "style",
          `${style} --tooltip-color: ${color};`
        )
        $mouse.classList.add("hover-article")
        $mouseMiddle.classList.add("hover-article")
      })
      $article.addEventListener("mouseleave", () => {
        $mouse.classList.remove("hover-article")
        $mouseMiddle.classList.remove("hover-article")
      })
    }
    for (const $bubble of $bubbles) {
      $bubble.addEventListener("mouseenter", () => {
        const hoverText = $bubble.getAttribute("data-circle")
        const color = $bubble.getAttribute("data-color")
        const style = $mouseMiddle.getAttribute("style")
        $mouseMiddle.setAttribute("data-circle", hoverText)
        $mouseMiddle.setAttribute(
          "style",
          `${style} --tooltip-color: ${color};`
        )
        $mouse.classList.add("hover-bubble")
        $mouseMiddle.classList.add("hover-bubble")
      })
      $bubble.addEventListener("mouseleave", () => {
        $mouse.classList.remove("hover-bubble")
        $mouseMiddle.classList.remove("hover-bubble")
      })
    }
    for (const $nomouse of $nomouses) {
      $nomouse.addEventListener("mouseenter", () => {
        $mouse.classList.add("disappear")
        $mouseMiddle.classList.add("disappear")
      })
      $nomouse.addEventListener("mouseleave", () => {
        $mouse.classList.remove("disappear")
        $mouseMiddle.classList.remove("disappear")
      })
    }
    $bigBubbles.forEach(($bigBubble, index) => {
      $bigBubble.addEventListener("mouseenter", () => {
        $mouse.classList.add("big")
        $mouseMiddle.classList.add("big")
        $mouseMiddle.classList.add(`bubble${index + 1}`)
      })
      $bigBubble.addEventListener("mouseleave", () => {
        $mouse.classList.remove("big")
        $mouseMiddle.classList.remove("big")
        $mouseMiddle.classList.remove(`bubble${index + 1}`)
      })
    })
    $horizontalScrolls.forEach(($horizontalScroll, index) => {
      $horizontalScroll.addEventListener("mouseenter", () => {
        $mouse.classList.add("arrow")
        $mouseMiddle.classList.add("arrow")
      })
      $horizontalScroll.addEventListener("mouseleave", () => {
        $mouse.classList.remove("arrow")
        $mouseMiddle.classList.remove("arrow")
      })
    })
  }, [])

  return (
    <LayoutStyled onMouseMove={handleMouseMove}>
      {hasCursor ? <Mouse /> : null}
      <Header
        pageMenu={pageValueMenu}
        noMenu={noMenu}
        blackLogo={blackLogo}
        hasScrolled={hasScrolled}
        connexionClick={connexionClick}
        contactLink={data.prismicContact.data.lien[0].text}
      >
        {headerString}
      </Header>
      <div>
        <PageTransition
          defaultStyle={
            slide
              ? {
                  transition: "left 500ms 1000ms ease-in-out",
                  left: "100vw",
                  position: "absolute",
                }
              : {
                  transition: "opacity 500ms ease-in-out",
                  opacity: 0,
                }
          }
          transitionStyles={
            slide
              ? {
                  entering: { left: "0" },
                  entered: { left: "0", position: "relative" },
                  exiting: { left: "100vw" },
                }
              : {
                  entering: { opacity: 1 },
                  entered: { opacity: 1 },
                  exiting: { opacity: 1 },
                }
          }
          transitionTime={500}
        >
          {children}
        </PageTransition>
        {/* {isValid !== undefined ? null : <Cookie />} */}
        <Cookie />
        <Footer footerText={footerText} green={green} />
      </div>
    </LayoutStyled>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
