import React, { useRef, useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { size } from "../../elements/font"

const Container = styled.div`
    padding: 15px 2.4vw;
    background: #1F4ED2;
    position: fixed;
    top: 50%;
    left 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    max-width: 1040px;
    max-height: 583px;
    width: 54%;
    min-width: 600px;
    height: 53%;
    z-index: 2001;
    transition: .3s ease-out;
    cursor: default;
    & > * {
        cursor: default;
    }
    &.closed {
        opacity: 0;
        pointer-events: none;
    }
    &.open {
        opacity: 1;
        pointer-events: all;
    }
    @media screen and (max-width: 815px) {
        width: 100vw;
        height: 100%;
        max-height: 100%;
        min-width: 0%;
        z-index: 2222001;
    }
    @media screen and (max-height: 640px) {
        width: 100vw;
        height: 100%;
        max-height: 100%;
        max-width: 100%;
    }
`
const TopBanner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const Title = styled.span`
  font-family: "Alata", sans-serif;
  color: #fff;
  font-size: ${size.SSM};
  line-height: 41px;
  letter-spacing: 0.05em;
  cursor: default;
  @media screen and (max-width: 815px) {
    font-family: "Alata", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: ${size.SM};
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    margin-left: 20px;
  }
`
const CloseBtn = styled.button`
  border: 1px solid #fff;
  border-radius: 5px;
  background: none;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-out;
  svg {
    pointer-events: none;
    transition: 0.3s ease-out;
    width: 23px;
    height: 23px;
    cursor: pointer;
  }
  &:hover {
    background: #fff;
    svg {
      path {
        fill: #1f4ed2;
      }
    }
  }
  @media only screen and (max-width: 900px) {
    margin-right: 20px;
    svg {
      width: 40px;
      height: 40px;
    }
  }
`
const ConnexionContainer = styled.div`
  min-width: 400px;
  width: 52%;
  overflow-y: auto;
  min-height: 80%;
  margin: 2vh auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 815px) {
    min-width: calc(100% - 20px * 2);
    margin: 12vh 20px;
    justify-content: flex-start;
    padding: 15% 0;
    max-width: 500px;
  }
  @media only screen and (max-height: 700px) and (min-height: 640px) {
    min-width: 400px;
    width: 100%;
    overflow-y: auto;
    min-height: inherit;
    margin: 0;
  }
  @media screen and (max-height: 730px) {
    justify-content: space-around;
  }
  @media screen and (max-height: 480px) {
    margin: 0vh 20px;
  }
`
const Labels = styled.label`
  color: #fff;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-family: "Archivo Black", sans-serif;
  font-weight: normal;
  font-size: ${size.XS};
  font-weight: 100;
  line-height: 22px;
  letter-spacing: 0.05em;
  padding: 0 0 1vh 0;
  cursor: default;
`
const Input = styled.input`
  width: 100%;
  position: relative;
  background: none;
  border: 1px solid #fff;
  padding: 3px 1vh;
  border-radius: 5px;
  font-size: ${size.XXS};
  letter-spacing: 0.1em;
  text-align: center;
  cursor: text;
  color: #fff;
  font-family: Alata, sans-serif;
  font-weight: 400;
  &.margin {
    margin: 0 0 0.5vh 0;
  }
`
const LoginContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 440px;
  align-items: center;
`
const ContainerInput = styled.div`
  position: relative;
  width: 82%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > svg {
    width: auto;
    height: 62%;
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%) scale(0.7);
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    max-width: 400px;
  }
`
const ShowPassword = styled.div`
  width: fit-content;
  background: none;
  border: none;
  width: 34px;
  height: 100%;
  position: absolute;
  bottom: 13%;
  right: 3px;
  transform: scale(0.8);
  outline: none;
  cursor: pointer;
  svg {
    transform: translateY(5%);
    pointer-events: none;
    width: 100%;
    height: 100%;
  }
`
const PasswordContainer = styled.div`
  margin: 6vh 0 7vh 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  max-width: 440px;
  justify-content: space-between;
  @media only screen and (max-height: 730px) and (min-height: 640px) {
    margin: 3vh 0;
  }
`
const Forgot = styled(Link)`
  color: #fff;
  font-family: Open Sans, sans-serif;
  margin: 0;
  font-size: ${size.XXS};
  cursor: pointer;
  margin-top: 1vh;
  cursor: pointer;
`
const Connect = styled.button`
  padding: 5px 4.5vw;
  border: 2px solid #fff;
  text-transform: uppercase;
  color: #fff;
  font-family: Archivo Black, sans-serif;
  font-weight: 300;
  letter-spacing: 0.05em;
  font-size: ${size.XS};
  background: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease-out;
  &:hover {
    color: #1f4ed2;
    background: #fff;
  }
  @media only screen and (max-width: 900px) {
    padding: 15px 45px;
  }
  @media only screen and (max-height: 800px) {
    margin-bottom: 20px;
  }
`
const Showed = styled.div`
  position: absolute;
  width: 70%;
  height: 3px;
  background: #fff;
  transform: ${props =>
    props.pass === false
      ? "rotate(-60deg) translate(-50%, -50%)"
      : "rotate(-60deg) translate(-50%, 50%)"};
  transform-origin: left top;
  left: 50%;
  top: 55%;
  cursor: pointer;
  opacity: ${props => (props.pass === false ? "1" : "0")};
  transition: 0.3s ease-out;
`
const ConnexionSentence = styled.p`
  display: none;
  @media only screen and (max-width: 900px) {
    display: block;
    color: #fff;
    font-family: "Archivo Black", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: ${size.XXS};
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    margin-top: 10vh;
  }
`
const Popup = ({ open, closeBtn }) => {
  const password = useRef(null)
  const [pass, setpass] = useState(true)
  return (
    <Container className={open + " nomouse"}>
      <TopBanner>
        <Title>novlini member</Title>
        <CloseBtn onClick={closeBtn}>
          <svg
            width="24"
            height="26"
            viewBox="0 0 24 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.9223 13L23.6635 1.38848C23.8268 1.19551 23.6895 0.902344 23.4372 0.902344H20.4758C20.3014 0.902344 20.1344 0.980273 20.0194 1.11387L11.9852 10.6918L3.95103 1.11387C3.8397 0.980273 3.67271 0.902344 3.49458 0.902344H0.533256C0.280913 0.902344 0.143608 1.19551 0.306889 1.38848L10.0481 13L0.306889 24.6115C0.270313 24.6545 0.246847 24.7071 0.239278 24.7631C0.231709 24.819 0.240355 24.876 0.264189 24.9272C0.288023 24.9784 0.326044 25.0216 0.373738 25.0518C0.421432 25.0821 0.476796 25.098 0.533256 25.0977H3.49458C3.669 25.0977 3.83599 25.0197 3.95103 24.8861L11.9852 15.3082L20.0194 24.8861C20.1307 25.0197 20.2977 25.0977 20.4758 25.0977H23.4372C23.6895 25.0977 23.8268 24.8045 23.6635 24.6115L13.9223 13Z"
              fill="white"
            />
          </svg>
        </CloseBtn>
      </TopBanner>
      <ConnexionContainer>
        <LoginContainer>
          <Labels htmlFor="login">Login</Labels>
          <ContainerInput>
            <Input autoComplete="off" type="text" id="login" name="login" />
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.707 17.7071L15 8.41412L10.586 4.00012L1.293 13.2931C1.16506 13.4212 1.07418 13.5816 1.03 13.7571L0 19.0001L5.242 17.9701C5.418 17.9261 5.579 17.8351 5.707 17.7071ZM18 5.41412C18.3749 5.03907 18.5856 4.53045 18.5856 4.00012C18.5856 3.4698 18.3749 2.96118 18 2.58612L16.414 1.00012C16.0389 0.625182 15.5303 0.414551 15 0.414551C14.4697 0.414551 13.9611 0.625182 13.586 1.00012L12 2.58612L16.414 7.00012L18 5.41412Z"
                fill="white"
              />
            </svg>
          </ContainerInput>
        </LoginContainer>
        <PasswordContainer>
          <Labels htmlFor="password">Password</Labels>
          <ContainerInput>
            <Input
              autoComplete="off"
              ref={password}
              className="margin"
              type={pass ? "password" : "text"}
              id="password"
              name="password"
            />
            <ShowPassword onClick={() => setpass(!pass)}>
              <svg
                width="23"
                height="15"
                viewBox="0 0 23 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.947 7.49844C14.947 9.32562 13.4009 10.8068 11.4938 10.8068C9.58659 10.8068 8.04053 9.32562 8.04053 7.49844C8.04053 5.67124 9.58659 4.19004 11.4938 4.19004C13.4009 4.19002 14.947 5.67126 14.947 7.49844ZM11.5 0.609863C9.52535 0.618622 7.47857 1.09934 5.54101 2.01971C4.1024 2.73121 2.70039 3.73505 1.48338 4.97407C0.885653 5.60655 0.123261 6.52238 0 7.49951C0.0145667 8.34593 0.922492 9.39067 1.48338 10.025C2.62463 11.2153 3.99012 12.1911 5.54101 12.9801C7.34787 13.857 9.34781 14.3618 11.5 14.3899C13.4765 14.381 15.5229 13.8948 17.4583 12.9801C18.8969 12.2686 20.2996 11.264 21.5166 10.025C22.1143 9.39249 22.8767 8.47665 23 7.49951C22.9854 6.65309 22.0775 5.60832 21.5166 4.97404C20.3754 3.78367 19.0092 2.80861 17.4583 2.01967C15.6523 1.14344 13.6475 0.642044 11.5 0.609863ZM11.4985 2.32045C14.4908 2.32045 16.9165 4.6395 16.9165 7.50026C16.9165 10.361 14.4908 12.6801 11.4985 12.6801C8.50626 12.6801 6.08061 10.361 6.08061 7.50026C6.08061 4.6395 8.50626 2.32045 11.4985 2.32045Z"
                  fill="white"
                />
              </svg>
              <Showed pass={pass} />
            </ShowPassword>
          </ContainerInput>
          <Forgot to="/">mot de passe oublié ?</Forgot>
        </PasswordContainer>
        <Connect>Connexion</Connect>
        <ConnexionSentence>
          N’hésitez pas à nous appeler si vous avez des questions 😉.
        </ConnexionSentence>
      </ConnexionContainer>
    </Container>
  )
}

export default Popup
