const size = {
    XXXXS: '0.5rem',
    XXXS: '0.6rem',
    XXS: '0.7rem',
    XS: '0.8rem',
    S: '1rem',
    SM: '1.1rem',
    SSM: '1.2rem',
    M: '1.4rem',
    L: '2rem',
    XL: '2.2rem'
}

const family = {
    Archivo: '"Archivo Black", sans-serif',
    Alata: '"Alata", sans-serif',
    SpaceMono: '"Space Mono", sans-serif',
    NATS: '"NATS Regular", sans-serif',
    RobotoMono: '"Roboto Mono", sans-serif',
    Alfa: '"Alfa Slab One", sans-serif'
}

export { size, family }