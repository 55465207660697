import React, { useState, useEffect } from "react"
import Cookies from "js-cookie"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { size } from "../../elements/font"

const CookieContainer = styled.div`
  position: fixed;
  z-index: 320;
  bottom: 0;
  right: 0;
  padding: 18px 38px 20px 18px;
  max-width: 480px;
  width: 63%;
  min-width: 320px;
  max-height: 220px;
  background: #ffe4f9;
  transform: translateY(100%);
  transition: 0.5s ease transform;
  animation: ${props =>
    props.Anim
      ? "movingContainer 3s ease infinite forwards normal"
      : "nothing"};
  @keyframes movingContainer {
    0% {
      transform: translateY(0%);
    }
    50% {
      transform: translateY(3%);
    }
    100% {
      transform: translateY(0%);
    }
  }
  @media screen and (max-width: 815px) {
    min-width: 100vw;
  }

  &.show {
    transform: translateY(0%);
  }
`
const Title = styled.span`
  font-family: Alata, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: ${size.S};
  margin-bottom: 4px;
  text-transform: uppercase;
  @media screen and (max-width: 815px) {
    font-size: ${size.XXS};
  }
`
const Text = styled.p`
  font-family: Space Mono, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: ${size.XXXS};
  line-height: 17px;
  max-width: 422px;
  text-align: justify;
  padding: 0 25px 0 0;
  margin: 6px 0 18px;
  @media screen and (max-width: 815px) {
    font-size: ${size.XXXS};
    margin-bottom: 15px;
    line-height: 15px;
    padding: 0 35px 0 0;
    a {
      font-size: ${size.XXXS};
    }
  }
`
const Accept = styled.button`
  font-family: Alata, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: ${size.S};
  border: 1px solid #000000;
  padding: 6px 32px 6px 8px;
  background: none;
  text-transform: uppercase;
  position: relative;
  &::before {
    content: "";
    background: #000;
    position: absolute;
    height: 2px;
    width: 85%;
    bottom: 15%;
    transform: scaleX(1);
    transform-origin: left center;
    transition: 0.3s ease-out;
  }
  &::after {
    content: "";
    z-index: 20;
    position: absolute;
    left: 100%;
    transform: translate(-156%, 105%) rotate(220deg);
    transform-origin: top left;
    width: 12px;
    height: 20px;
    border-top: 3px solid #000;
    border-left: 3px solid #000;
  }
  &:hover {
    &::before {
      transform: scaleX(1.05);
    }
  }
`
const ImgContainer = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  left: 165px;
  animation: movingCookie 8s ease-in-out infinite forwards normal;
  @keyframes movingCookie {
    0% {
      transform: translateX(0%) rotate(0);
    }
    50% {
      transform: translateX(500%) rotate(360deg);
    }
    100% {
      transform: translateX(0%) rotate(0);
    }
  }
  @media screen and (max-width: 815px) {
    width: 35px;
    height: 35px;
  }
`
const CloseBtn = styled.button`
  border: none;
  background: none;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 2%;
  top: 13%;
  svg {
    width: 28px;
    height: 28px;
  }
`
const LinkKnow = styled.a`
  color: #000;
  font-family: Space Mono, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: ${size.XXXS};
  line-height: 17px;
  text-align: justify;
  text-decoration-line: underline;
`

const Cookie = () => {
  const [showWindow, setShowWindow] = useState(false)
  const [Anim, setAnim] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      cookie: file(relativePath: { eq: "cookie.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 700) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  useEffect(() => {
    setTimeout(() => {

      setShowWindow(true)
    }, 1000)
    setTimeout(() => setAnim(true), 2000)
  }, [])

 if (typeof window !== 'undefined' && localStorage.getItem('cookiesAcceptedNovlini') == 'true') {return null } else return (
<CookieContainer className={`${showWindow ? "show" : ""}`} Anim={Anim}>
<Title>Politique des cookies</Title>
<Text>
  Notre site internet utilise des cookies. En continuant votre navigation
  à travers notre site, vous acceptez l’utilisation de nos cookies.{" "}
  <LinkKnow
    className="click"
    href="https://www.cnil.fr/fr/cookies-et-autres-traceurs"
    target="_blank"
  >
    En savoir plus.
  </LinkKnow>
</Text>
<ImgContainer>
  <Img loading="eager" fluid={data.cookie.childImageSharp.fluid} />
</ImgContainer>
<CloseBtn
  className="click"
  onClick={() => {
    setAnim(false)
    setTimeout(() => {
      setShowWindow(false)
    }, 100)
  }}
>
  <svg
    width="33"
    height="34"
    viewBox="0 0 33 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.2198 33L0.633545 1M0.633545 33L32.2198 1L0.633545 33Z"
      stroke="black"
      strokeLinejoin="round"
    />
  </svg>
</CloseBtn>
<Accept
  className="click"
  onClick={() => {
    Cookies.set("cookiesAcceptedNovlini", "true", { expires: 30 })
    localStorage.setItem('cookiesAcceptedNovlini', true)
    setAnim(false)
    setTimeout(() => {
      setShowWindow(false)
    }, 100)
  }}
>
  J'accepte
</Accept>
</CookieContainer>)
  
}

export default Cookie
