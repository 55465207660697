

import React from "react"
import styled from "styled-components"

const CloseBtn = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
  height: 55px;
  width: 55px;
  border-radius: 66px;
`

const Story = ({ url, close }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 999,
        backdropFilter: "blur(16px)",
        backgroundColor: "rgba(0,0,0,0.2)",
      }}
    >
      <CloseBtn onClick={close}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ height: 20, width: 20 }}
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </CloseBtn>
      <iframe
        title="unique"
        src={url}
        frameborder="0"
        scrolling="no"
        style={{
          width: "100%",
          minHeight: "100%",
          position: "relative",
          margin: "0 auto",
          display: "block",
        }}
      ></iframe>
    </div>
  )
}

export default Story
